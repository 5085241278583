







export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    styling: {
      type: Object,
      default: () => {}
    },
    color: {
      type: String,
      default: 'black'
    },
    title: {
      type: String,
      default: 'This is Toolbar'
    },
    dark: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    }
  }
};
